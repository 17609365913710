<template>
  <div>
    <h1 class="mb-0">Reset Password</h1>
    <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
    <form class="mt-4" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="exampleInputEmail1">Email address</label>
        <input type="email" v-model="user.email" value="" class="form-control mb-0" id="exampleInputEmail1"
          aria-describedby="emailHelp" placeholder="Enter email" required>
      </div>

      <div class="d-inline-block w-100">
        <b-button type="submit" class="btn btn-primary float-right">Reset Password</b-button>
      </div>

    </form>
  </div>
</template>
<script>
export default {
  name: 'RecoverPassword1',
  data: () => ({
    user: {
      email: '',
    },
  }),
  methods: {
    onSubmit() {
      this.axios
        .post('/forget-password', {
          email: this.user.email,
        })
        .then(function (response) {
          // window.location.href = './email-sent'
          setTimeout(() => {
            window.location.href = '/auth/login'
          }, 1000);
        })
        .catch(function (error) {
        })
    },
  }
}

</script>
